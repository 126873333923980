header.header {
  padding: 0 15px;
  position: fixed;
  transition: $trans-fast;
  width: 100%;
  z-index: 20;

  @include mobile-xs {
    background-color: $brand;
  }

  &.open {
    @include tablet {
      height: 100vh;
      overflow-y: scroll;
    }
  }

  &.open,
  &.with-bg,
  &.scroll-bg {
    background-color: $brand;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .logo {
    margin-left: 35px;
    padding: 26px 0;

    @include tablet {
        margin-left: 0;
    }
  }

  .sublogo {
    align-items: center;
    background-color: $brand;
    color: $default;
    display: flex;
    font-size: 1.2rem;
    height: 80px;
    justify-content: center;
    text-align: right;
    transition: $trans-fast;
    width: 200px;

    @include tablet {
        display: none;
    }

    &:hover {
      color: $orange;
    }

    img {
      margin-left: 25px;
    }
  }

  .menumain {
    margin-left: auto;

    @include laptop-and-greater {
      display: flex!important;
    }

    @include tablet {
      display: none;
      flex-direction: column;
      order: 10;
      width: 100%;
    }

    .sublogo {

        @include tablet {
            display: flex;
            width: auto;
        }

        @include laptop-and-greater {
          display: none;
        }
    }
  }

  //Level 0
  #brysiewicz-menu {
    display: flex;
    width: 100%;

    @include tablet {
      flex-direction: column;
    }

    @include tablet-only {
        margin-top: 80px;
    }

    >li {
      height: 80px;
      display: flex;
      align-items: center;
      position: static;

      @include tablet {
        min-height: 64px;
        height: auto;
        flex-direction: column;
        align-items: center;
      }

      &.current-menu-item > a,
      &.current-menu-ancestor > a {

        &:before {
          width: calc(100% + 10px);
        }
      }

      a {
        color: $default;
        font-size: 1.6rem;
        font-weight: 500;
      }

      >a {
        position: relative;

        @media (min-width: 1025px) and (max-width: 1279px) {
          font-size: 1.4rem;
        }

        @include tablet {
          font-size: 2.4rem;
        }

        &:before {
          background-color: $orange;
          content: '';
          display: block;
          height: 14px;
          position: absolute;
          left: -5px;
          bottom: -3px;
          width: 0;
          z-index: -1;
          transition: $trans-fast;
        }

        &:hover {

          &:before {
            width: calc(100% + 10px);
          }
        }
      }

      &:not(:last-child) {
        margin-right: 36px;

        @media (min-width: 1025px) and (max-width: 1279px) {
          margin-right: 16px;
        }

        @include tablet {
          margin-right: 0;
        }
      }

      &.dropdown {
        transition: $trans-fast;

        >a {
          padding-right: 18px;

          &::after {
            font-family: 'Font Awesome\ 5 Free';
            content: ' \f107';
            font-weight: 900;
            position: absolute;
            right: 0;
            top: 0;
            transition: $trans-fast;
          }
        }

        &.open {

          >a {

            &:before {
              width: calc(100% + 10px);
            }

            &:after {
              transform: rotate(180deg);
            }
          }
        }

        //Level 1
        >ul.dropdown-menu {
          background-color: $brand;
          border: 0;
          border-radius: 0;
          flex-wrap: wrap;
          margin: 0;
          padding: 30px 30px 60px 33%;
          width: 100%;

          @include tablet {
            flex-direction: column;
            flex-wrap: nowrap;
            padding-left: 30px;
            padding-bottom: 0;
            position: relative;
            top: 3px;
          }

          >li {
            margin-bottom: 30px;

            @include tablet {
                text-align: center;
            }

            @include laptop-and-greater {
              flex-grow: 1;
              flex-basis: 50%;
              min-width: 50%;
              max-width: 50%;
            }

            &.current-menu-item > a {
              color: $orange;
            }

            >a {
              transition: $trans-fast;

              @media (min-width: 1025px) and (max-width: 1279px) {
                font-size: 1.4rem;
              }

              &:hover {
                color: $orange;
              }
            }
          }
        }
      }
    }
  }

  .search-panel {
    border-left: 1px solid $default;
    display: flex;
    align-items: center;
    height: 45px;
    margin-left: 24px;
    margin-right: 24px;
    padding-left: 24px;
    position: relative;

    @include tablet {
      margin-left: auto;
    }

    .main-search {
      display: none;
      position: absolute;
      bottom: -60px;
      right: 0;

      .input-search {
        background: none;
        background-color: $orange;
        border: none;
        color: $default;
        font-size: 1.4rem;
        height: 50px;
        padding: 0 15px;
        width: 256px;

        &::placeholder {
          color: $default;
          font-size: 1.4rem;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .menu-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: none;
    width: 25px;
    margin-left: 24px;
    min-width: 25px;
    height: 18px;
    position: relative;
    padding: 0;
    transition: $trans-medium;

    @include tablet {
      display: block;
    }

    &:focus {
      outline: none;
    }

    .bar {
      background-color: $default;
      position: absolute;
      width: 100%;
      height: 2px;
      transition: $trans-fast;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(3) {
        bottom: 0;
      }
    }

    &.open {

      .bar {
        background-color: $default;

        &:nth-child(1) {
          transform: translateY(8px) rotate(-45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateY(-8px) rotate(45deg);
        }
      }
    }
  }
}
