.sidebar {
  background-color: $brand;
  color: $default;
  padding: 40px 45px 25px;

  &-group {
    margin-bottom: 50px;
  }

  &-title {
    border-bottom: 1px solid rgba($gray-border, .1);
    color: $orange;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 1;
    margin-bottom: 20px;
    padding-bottom: 15px;
  }

  .post-switch {
    display: flex;
    justify-content: center;

    a {
      color: $default;
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: bold;
      transition: $trans-fast;

      &:hover {
        color: $orange;
      }
    }

    svg {
      transition: $trans-fast;
    }

    .prev {
      margin-right: 10px;

      &:hover {

        svg {
          transform: rotate(180deg) translateX(5px);
        }
      }

      svg {
        margin-right: 10px;
        transform: rotate(180deg);
      }
    }

    .next {
      margin-left: 10px;

      &:hover {

        svg {
          transform: translateX(5px);
        }
      }

      svg {
        margin-left: 10px;
      }
    }
  }

  .tags {
    margin-right: -10px;

    .tag {
      color: rgba($default, .5);
    }
  }

  .sources {
    color: $default;
    font-size: 1.3rem;
    font-weight: 300;

    p {

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    a {
      color: $default;
      transition: $trans-fast;

      &:hover {
        color: $orange;
      }
    }
  }

  .popular-posts {

    .post-excerpt-sidebar {

      &:not(:last-child) {
        margin-bottom: 40px;
      }

      .post-excerpt-title {
        transition: $trans-fast;

        &:hover {
          color: $orange;
        }
      }
    }
  }

  .read {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @include mobile-xs {
        flex-direction: column;
        align-items: center;
    }

    a {
      display: block;

      &:not(:last-child) {
        margin-right: 20px;

        @include mobile-xs {
            margin-bottom: 20px;
            margin-right: 0;
        }
      }

      &:hover {

        svg {
          path {
            fill: $default;
            opacity: 1;
          }
        }
      }
    }

    svg {

      path {
        fill: $gray-border;
        opacity: .25;
        transition: $trans-fast;
      }
    }
  }

  .share {
    display: flex;
    justify-content: center;
    margin-top: -5px;

    .jssocials-shares {
      display: flex;
    }

    a {
      color: $default;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      transition: $trans-fast;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &:hover {
        background-color: #006cac;
      }

      img {
        max-width: 20px;
        max-height: 20px;
      }
    }
  }

  .tools {
    margin-top: 45px;
    margin-bottom: 110px;

    .tools-list {
      display: flex;
      justify-content: center;

      @include mobile-xs {
          flex-direction: column;
          align-items: center;
      }

      li {
        position: relative;

        &:not(:last-child) {
          margin-right: 30px;

          @include mobile-xs {
              margin-bottom: 30px;
              margin-right: 0;
          }
        }

        &:hover {
          .tool-description {
            opacity: 1;
          }
        }

        svg {
          width: 25px;
          height: 25px;

          &:hover {
            g, path {
              fill: $orange;
            }
          }
        }
      }
    }

    .tool-description {
      background-color: $orange;
      font-size: 1.5rem;
      font-weight: 300;
      transform: translateX(calc(-50% + 10px));
      margin-top: 25px;
      min-height: 40px;
      padding: 10px 15px;
      position: absolute;
      transition: $trans-fast;
      opacity: 0;
      white-space: nowrap;

      &::before {
        background-color: $orange;
        content: '';
        display: block;
        width: 1px;
        height: 20px;
        position: absolute;
        top: -20px;
        left: 50%;
      }
    }
  }
}
