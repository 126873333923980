footer.footer {
  background: $brand;
  color: $default;

  &.footer-sub {
    padding-top: 105px;
  }

  a:link,
  a:visited,
  a:hover,
  a:active{
    color: $default!important;
    transition: $trans-fast;

    &:hover {
      color: $orange!important;
    }
  }

  .row {
    padding: 75px 0;

    .footer-heading {
      font-size: 1.7rem;
      font-weight: bold;
      line-height: 2.06;
      letter-spacing: 0.3px;
      color: $orange;
      padding-bottom: 35px;
    }

    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 50px;
    }

    .footer-description {
      font-size: 1.3rem;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.86;
      letter-spacing: 0.3px;
      text-align: left;
      padding-bottom: 25px;

      i {
        font-size: 1.2rem;
        margin-right: 10px;
      }

      &.desc {
        font-size: 1.4rem;
      }
    }
  }
}
