/* RESPONSIVNESS */

$laptop-max: 1600px;
$laptop-middle: 1200px;
$laptop-min: 1025px;
$laptop-1366: 1366px;
$laptop-mac: 1440px;
$tablet-max: 1024px;
$tablet-min: 768px;
$mobile-max: 767px;
$mobile-xs-max: 575px;

@mixin mobile-xs {
    @media (max-width: $mobile-xs-max) {
        @content
    }
}

@mixin mobile {
    @media (max-width: $mobile-max) {
        @content
    }
}

@mixin tablet {
    @media (max-width: $tablet-max) {
        @content
    }
}

@mixin tablet-only {
    @media (max-width: $tablet-max) and (min-width: $tablet-min) {
        @content
    }
}

@mixin tablet-and-greater {
    @media (min-width: $tablet-min) {
        @content
    }
}

@mixin laptop {
    @media (max-width: $laptop-max) {
        @content
    }
}

@mixin laptop-1366 {
    @media (max-width: $laptop-1366) {
        @content
    }
}

@mixin laptop-mac {
    @media (max-width: $laptop-mac) {
        @content
    }
}

@mixin laptop-only {
    @media (max-width: $laptop-max) and (min-width: $laptop-min) {
        @content
    }
}

@mixin laptop-middle {
    @media (max-width: $laptop-middle) and (min-width: $laptop-min) {
        @content
    }
}

@mixin laptop-and-greater {
    @media (min-width: $laptop-min) {
        @content
    }
}

@mixin laptop-1366-and-greater {
    @media (min-width: $laptop-1366) {
        @content
    }
}

$default: #ffffff;
$black: #000000;
$text-color: #121212;
$brand: #171f33;
$orange: #f76901;
$orange-light: #f98921;
$gray: #737373;
$gray-border: #d8d8d8;
$gray-utils: #7b7b7b;
$gray-newsletter: #404040;

$gray-img1: #272727;
$gray-img2: #595959;
$gray-img3: #ababab;


$trans-fast: all .25s ease-in-out;
$trans-medium: all .5s ease-in-out;
$trans-slow: all .75s ease-in-out;
