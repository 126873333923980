body {
    &.contrast {
        background: #000000 !important;
        color: #ffff00 !important;

        * {
            &:not(.image-column):not(.image):not(.swiper-slide):not(.bar):not(.dropdown) {
                background: #000000 !important;
                color: #ffff00 !important;
                border-color: #ffff00 !important;

                &::before,
                &::after {
                    background: #000000 !important;
                    border-color: #ffff00 !important;
                    color: #ffff00 !important;
                }
            }
        }

        .bar {
            background: #ffff00 !important;

            &::before,
            &::after {
                background: #ffff00 !important;
            }
        }

        .active .bar:nth-child(2) {
            background: transparent !important;
        }

        .collapsible-row-parent {
            td:first-child::before {
                filter: invert(1) sepia(100%) saturate(100000%);
            }
        }

        svg:not(.no-contrast) {

            path:not(.circle-pie):not(.circle-logo),
            rect,
            polygon,
            circle {
                fill: #ffff00 !important;
                stroke: #ffff00 !important;
            }

            line,
            polyline {
                stroke: #ffff00 !important;
            }

            text {
                fill: #ffff00 !important;
            }

            .circle-pie {
                fill: #000 !important;
                stroke: #ffff00 !important;
            }

            .circle-logo {
                fill: #000 !important;
            }
        }
    }

    &.print-version {
        height: 297mm;
        width: 210mm;
        margin-left: 0;

        .col-md-10 {
            flex:  0 0 100%;
            max-width: 100%;
        }

        .offset-md-1 {
            margin-left: 0;
        }

        header,
        .pages,
        .table-tools,
        .currency-bar,
        aside,
        footer,
        .top
        .breadcrumb-container,
        .post-bottom,
        .newsletter,
        .sidebar {
            display: none;
        }

        #hide-print-version, .currency-elements>.element {
            display: block!important;
        }

        .container {
            max-width: 100%;
        }

        .top .gri {
            height: auto;
        }

        .subpage {
          .content-container {
            margin-left: 0;
          }

            .info-icons {
              .col-icons {
                padding: 15px;
              }
                .value {
                  font-size: 3rem;
                }
            }

            .documents-to-download-2 ul>li .title {
                width: 85%;
            }


            .accordion {
                .collapse {
                    display: block;
                }
            }

            .accordion-with-image {
              .col-content {
                justify-content: center;
                padding-right: 0;
              }
            }

            .quotation-with-image {
              .col-quotation {
                padding: 30px;
              }
            }


            .col-tabs {
                .nav-pills {
                    display: none!important;
                }
                .tab-pane {
                    display: block;
                    opacity: 1;
                }
            }
            .table-container {
                .collapsible-row-child .row-content {
                    display: block;
                }
                .buttons {
                    display: none;
                }
            }

            .column-with-photo {
                >div {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding-left: 15px!important;
                    padding-right: 15px!important;
                }
            }
        }
    }

    .subpage {
        &.with-highlighter * {
            cursor: url(../images/cr_marker.cur), auto;

            .highlighted {
                cursor: url(../images/cr_eraser.cur), auto;
            }
        }

        .highlighted {
            background-color: #fff4d1 !important;
        }
    }


    .currency-modal,
    .table-modal,
    .search-results-modal {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.7);

        .box {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: auto;
            width: 550px;
            background-color: #fff;
            border: 1px solid $gray;
            border-radius: 4px;
            padding: 32px;

            @include mobile {
                width: 100%;
                padding: 32px 15px;
            }

            .close-icon {
                position: absolute;
                top: 16px;
                right: 16px;
                cursor: pointer;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            .m-body {
                position: relative;
                float: left;
                width: 100%;

                .table-container{
                    padding-top: 35px;
                    .table-tools{
                        top: 0;
                    }
                }

                .left,
                .right {
                    position: relative;
                    float: left;
                    width: 50%;
                }

                .left {
                    border-right: 1px solid $gray;
                }

                .right {
                    padding-left: 32px;
                }

                h3 {
                    margin: 0 0 20px;
                }

                .radio-container {
                    display: inline-block;
                    position: relative;
                    width: 100%;
                    padding-left: 40px;
                    margin: 0;
                    font-weight: 400;
                    line-height: 24px;
                    cursor: pointer;

                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #ffffff;
                        border: solid 1px $gray;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;

                        &::after {
                            content: "";
                            position: absolute;
                            display: none;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: $brand;
                        }
                    }

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;

                        &:checked~.checkmark::after {
                            display: block;
                        }
                    }

                    &+.radio-container {
                        margin-top: 20px;
                    }
                }
            }

            .buttons {
                float: left;
                position: relative;
                width: 100%;
                padding-top: 24px;
                text-align: center;

                @include mobile {
                    padding-top: 32px;
                }

                button+button {
                    margin-left: 20px;
                }
            }
        }
    }


    .table-modal{
        .box{
            width: inherit;
            max-height: 80vh;
            padding: 50px 32px;
            position: relative;
            height: 100%;
            overflow: hidden;
            .m-body{
                height: 100%;
            }
        }
    }

    .search-results-modal {
        .box {
            width: 750px;
            max-width: 80%;
            .close-icon svg {
                path {
                    fill: $brand;
                }
            }
        }

        h2 {
            margin-bottom: 20px;

            i {
                color: $brand;
            }
        }

        .results-container {
            max-height: 70vh;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                >li {
                    position: relative;
                    padding-left: 24px;
                    margin: 12px 0;

                    &::before {
                        content: "\2022";
                        font-family: Verdana, sans-serif;
                        position: absolute;
                        left: 0;
                        color: $brand;
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }

                    >ul li::before {
                        content: "–";
                    }

                    a {
                        color: $brand;
                    }
                }
            }
        }
    }

    #hide-print-version {
        display: none;

        @media print {
            display: none !important;
        }
    }

    .tools-fields {
        display: none;
    }

    //Style dla podstron: Notatki, Koszyk z wydrukami

    .subpage {

        .print-list,
        .notes-list {
            margin-bottom: 35px;

            .empty {
                padding: 0;
            }

            .results {
                .row+.row {
                    margin-top: 20px;
                }

                .title a {
                    color: $brand;
                    font-weight: bold;
                    font-size: 2.5rem;
                    display: block;
                }

                .delete {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;

                    span {
                        color: #000;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 15px;
                    }

                    i {
                        vertical-align: middle;
                        color: $brand;
                    }
                }
            }

            .buttons {
                margin-top: 25px;
            }
        }

        .print-content {
            display: none;
        }

        .notes-tool {
            display: none;

            textarea {
                color: #000;
                resize: none;
                border-color: $gray;
                border-radius: 4px;
                min-height: 120px;
                padding: 10px 20px;
                margin-bottom: 30px;
                outline: none;
                width: 100%;
            }

            div {
              margin-bottom: 15px;
              text-align: center;
              width: 100%;
            }
        }
    }
}
