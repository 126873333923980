.pages {
  margin-bottom: 30px;

  .row {
    align-items: flex-start;
  }

  .page {
    display: flex;
    align-items: center;

    .left .arrow-container {

      &:hover {
        transform: translateX(-10px);
      }

      svg {
        transform: rotate(180deg);
      }
    }

    .right .arrow-container {

      &:hover {
        transform: translateX(10px);
      }
    }
  }

  .page-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 120px;
    padding: 20px 25px 25px;

    .label {
      color: $default;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .title {
      color: $default;
      font-weight: 300;
    }
  }

  .caption {
    margin-right: 35px;
  }

  .arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $trans-fast;

    img, svg {
      width: 25px;
      height: 19px;
    }
  }

  .left {
    background-color: $orange;
    display: flex;
    margin-top: 20px;
    width: 100%;

    .page-item {
      justify-content: flex-start;
    }

    .caption {
      margin-left: 35px;
      margin-right: 0;
      text-align: right;
    }
  }

  .right {
    background-color: $brand;
    display: flex;
    width: 100%;

    .page-item {
      text-align: right;
    }

    .caption {
      text-align: left;
    }
  }
}
