.homepage {

    .site-content {
        display: flex;
        align-items: flex-start;

        @include tablet {
            flex-direction: column;
        }

        .content {
            display: flex;
            flex-direction: column;
            width: 65%;

            @include tablet {
                width: 100%;
            }
        }

        .sidebar {
            width: 35%;

            @include tablet {
                margin-top: 30px;
                width: 100%;
            }
        }
    }

    .featured-news {
        background-color: $default;
        display: flex;
        align-items: flex-end;
        margin-bottom: 50px;
        min-height: 475px;
        overflow: hidden;
        position: relative;
        width: 100%;

        &:hover {

            .featured-news-bg {
                opacity: .5;
                transform: scale(1.1);
            }
        }

        &-bg {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: $trans-fast;
            z-index: 0;
        }
    }


    .posts {
        margin-top: -475px;
        margin-bottom: 90px;
        padding: 0 15px;

        .narrow {
            padding-left: 45px;

            @include tablet {
                padding-left: 0;
            }
        }

        .posts-more {
            margin-top: 50px;
            margin-bottom: 45px;
            text-align: center;
        }
    }

    .yt-movie{
        iframe{
            width: 100%;
            margin-bottom: 30px;
        }
    }

    .blogs {
        margin-bottom: 50px;
        padding: 0 15px;

        .col-blogs-list {
            background-color: $brand;
            padding: 34px 0 84px 0;
            position: relative;
            height: auto;

            @include tablet-and-greater {
                height: 586px;
                top: 50px;
            }

            @include tablet {
                padding: 40px 15px 105px 15px;
            }

            @include mobile-xs {
                padding: 40px 15px 40px 15px;
            }

            .section-title {
                margin-bottom: 40px;
            }

            ul {
                max-width: 350px;
                margin-left: 25px;

                li {

                    &:not(:last-child) {
                        margin-bottom: 30px;
                    }

                    a {
                        color: $default;
                        font-size: 1.8rem;
                        transition: $trans-fast;

                        &:hover,
                        &:focus {
                            color: $orange;
                        }
                    }
                }
            }
        }

        .col-blogs-images {

            .col-single-blog {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid $gray-border;

                &:hover {

                    .image {
                        opacity: .5;
                    }
                }

                &:nth-of-type(2n+1) {

                    .text {
                        border-left: 1px solid $gray-border;
                    }
                }

                .image {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 232px;
                    transition: $trans-fast;
                }

                .text {
                    border-right: 1px solid $gray-border;
                    height: 100%;
                    padding: 45px 30px 55px;

                    @include mobile {
                        border-left: 1px solid $gray-border;
                    }

                    .title {
                        color: $black;
                        font-size: 2.2rem;
                        font-weight: bold;
                        line-height: 1.25;
                        margin-bottom: 20px;

                        &.ext-url {

                            &::after {
                                display: inline-block;
                                font-family: 'Font Awesome\ 5 Free';
                                color: $gray;
                                content: ' \f35d';
                                font-size: 1rem;
                                font-weight: 900;
                                margin-left: 5px;
                                position: relative;
                                top: -3px;
                            }
                        }
                    }

                    .meta {
                        display: flex;
                        margin-bottom: 20px;

                        .date {
                            color: $black;
                            display: inline-block;
                            margin-right: 15px;
                        }

                        .author {
                            color: $gray;
                            display: flex;
                            flex-direction: column;
                            font-size: 1.4rem;
                            font-weight: bold;
                            letter-spacing: 0.3px;
                            text-transform: uppercase;

                            .caption {
                                font-size: 1.3rem;
                                font-weight: 300;
                                text-transform: none;
                            }
                        }
                    }

                    p {
                        color: $black;
                        line-height: 1.65;
                    }
                }
            }
        }
    }
}
