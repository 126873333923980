.subpage {

  .site-content {
    display: flex;
    align-items: flex-start;

    @include tablet {
        flex-direction: column;
    }

    .content {
      width: 800px;

      @include tablet {
          width: 100%;
      }

      &.shift-down{
        margin-top: 270px;
      }
    }

    .sidebar {
      width: 455px;

      @include tablet {
          margin-bottom: 30px;
          width: 100%;
      }
    }
  }

  .margin-bottom {
    margin-bottom: 30px;
  }

  .error404 {
    background-color: $default;

    h1 {
      color: $brand;
      font-size: 6.4rem;
      margin: 100px;

      @include tablet {
        font-size: 3.2rem;
        margin: 100px 15px;
      }
    }
  }

  div > ul:not([class]) {
    padding-left: 0;
    margin-bottom: 25px;
    list-style: none;

    p {
      margin-bottom: 0 !important;
    }

    li {
      line-height: 1.5;
    }

    > li {
      padding-left: 20px;
      margin: 12px 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: calc((1.5 * 1.6rem) / 2);
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        background-color: $orange;
      }

      > ul {
        list-style-type: none !important;
        padding-left: 0;

        > li {
          position: relative;
          counter-increment: list;
          padding-left: 20px;
          margin: 12px 0;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: calc((1.5 * 1.6rem) / 2);
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            background-color: $orange;
          }
        }
      }
    }
  }

  ol {
    counter-reset: list;
    list-style-type: none !important;
    padding-left: 0;
    margin: 0;

    > li {
      counter-increment: list;
      padding-left: 30px;
      margin: 12px 0;
      position: relative;

      &::before {
        content: counter(list) ". ";
        color: $orange;
        position: absolute;
        left: 0;
      }

      // a. b. c.
      > ol {
        > li {
          &::before {
            content: counter(list, lower-alpha) ". ";
          }

          // i. ii. iii.
          > ol {
            > li {
              &::before {
                content: counter(list, lower-roman) ". ";
              }
            }
          }
        }
      }

      ul {
        list-style-type: none !important;
        padding-left: 0;
        margin: 0;

        > li {
          padding-left: 20px;
          margin: 12px 0;
          position: relative;

          &::before {
            content: '';
            background: $orange;
            height: 4px;
            width: 4px;
            top: calc((1.5 * 1.6rem) / 2 - 2px);
            position: absolute;
            left: 0;
            font-size: 3rem;
          }
        }
      }
    }
  }

  .top {

    .post-excerpt-top {
      display: flex;
      align-items: center;
      flex-direction: row;
      min-height: 720px;
      padding-top: 245px;
      padding-bottom: 225px;
    }

    .tags {

      .tags-label {
        margin-right: 10px;
      }
    }
  }

  .post {
    margin-top: -220px;
    margin-bottom: 85px;
    position: relative;
    padding: 0 15px;
    z-index: 10;
  }

  .main {
    margin-bottom: 85px;

    @include tablet {
        margin-bottom: 30px;
    }
  }

  .modules {
    padding-right: 35px;

    @include tablet {
        padding-right: 0;
    }
  }

  .about-author {
    margin-bottom: 30px;
  }

  .posts-excerpts {
    margin-bottom: 30px;
  }

  .person {
    padding: 45px 35px 65px;

    &-items {
      //padding-left: 50px;

      @include mobile-xs {
          margin-top: 20px;
          padding-left: 0;
      }
    }
  }

  .sidebar .person {
    padding: 0;

    .person-items {
      padding-left: 0;
    }
  }

  .introduction {
    margin-top: 95px;
    margin-bottom: 30px;

    @include tablet {
        margin-top: 30px;
    }

    .col-image {
      background-color: $gray-img2;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 370px;
      margin-bottom: 25px;
    }

    .col-text {
      padding-right: 35px;

      @include tablet {
          padding-right: 0;
      }

      p {
        font-size: 1.5rem;
        line-height: 1.7;
      }

      p:first-of-type {

        strong {
          font-size: 1.6rem;
        }
      }

      p:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .single-column,
  .two-columns,
  .three-columns {

    .col,
    [class*="col-"] {

      &:not(:last-child) {

        @media (max-width: 991px) {
          margin-bottom: 30px;
        }
      }
    }

    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }

    p {
      font-size: 1.5rem;
      line-height: 1.7;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .single-column {

      iframe {
          display: block;
          margin: 0 auto;
          height: 400px;
          width: 100%;
      }
  }

  .responsive-image {

    img {
      max-width: 100%;
      margin-bottom: 25px;

      &.desktop-image {
        @include mobile {
          display: none;
        }
      }

      &.mobile-image {
        @include tablet-and-greater {
          display: none;
        }
      }
    }

    .popup-trigger {
      @include mobile {
        display: none;
      }
    }

    .popup-image-container {
      display: none;
      z-index: 9999;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(100, 100, 100, 0.5);

      @include mobile {
        display: none;
      }

      .popup-image {
        max-width: 95%;
        max-height: 95%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .quotation {
    margin-top: 55px;

    .col-quote {
      display: flex;

      svg {
        height: 45px;
        margin-top: -25px;
        margin-right: 40px;
        min-width: 54px;
      }

      .quote {
        color: $orange;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 0.4px;
        max-width: 960px;
      }
    }
  }

  .newsletter {
    margin-bottom: -105px;
    position: relative;
    z-index: 10;
  }
}
