.newslist {
  padding: 50px 15px 0;

  .section-title {
    margin-bottom: 45px;
  }

  .news-list {
    margin-bottom: 40px;

    &:first-of-type {
      border-top: 1px solid $gray-border;
    }

    &:not(:first-of-type) {
      border-bottom: 1px solid $gray-border;
      margin-bottom: 40px;
    }

    .col-item {
      display: flex;
      flex-direction: column;

      &:nth-of-type(2) {

        .text {
          height: 100%;
        }
      }

      &:nth-child(3n) {

        .text {
          border-left: 1px solid $gray-border;
        }
      }

      &:hover {

        .image {
          opacity: .5;
        }
      }

      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 232px;
        transition: $trans-fast;
        cursor: pointer;

        &.latest {
          @include tablet-and-greater {
            height: 464px;
          }
        }
      }

      .text {
        border-right: 1px solid $gray-border;
        border-bottom: 1px solid $gray-border;
        padding: 25px 30px 55px;        
        flex: 1 0 auto;

        @include mobile {
          border-left: 1px solid $gray-border;
        }

        .title {
          color: $black;
          font-size: 2.2rem;
          font-weight: bold;
          line-height: 1.25;
          margin-bottom: 20px;
          cursor: pointer;

        }

        .date {
          color: $black;
          display: inline-block;
          margin-bottom: 20px;
        }

        p {
          color: $black;
          line-height: 1.65;
        }
      }
    }
  }

  .newsletter {
    margin-bottom: -105px;
    position: relative;
    z-index: 10;
  }

  .pagination-nav {
    width: 100%;

    .page-numbers {
      font-size: 1.9rem;
      font-weight: bold;
      line-height: 2.06;
      letter-spacing: 0.3px;
      color: $orange;
      padding-bottom: 35px;
      margin-right: 10px;
    }

    .fa-arrow-left {
      transition: $trans-medium;
      &:hover {
        transform: translate(-5px);
      }
    }

    .fa-arrow-right {
      transition: $trans-medium;
      &:hover {
        transform: translate(5px);
      }
    }
  }
}
