@charset "UTF-8";
@import "variables";
@import "fonts";
@import "header";
@import "footer";
@import "sidebar";
@import "menu";
@import "homepage";
@import "subpage";
@import "newslist";
@import "publications";
@import "pages";
@import "tools";

html {
    font-size: 62.5%;

    @include mobile {
        font-size: 52.5%;
    }
}

body {
    background-color: $default;
    color: $text-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.5;

    &.no-scroll {

        @include tablet {
            overflow: hidden;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $orange-light inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: $default !important;
}

a:link,
a:visited,
a:hover,
a:active {
    color: $brand;
    text-decoration: none;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

p, h1, h2, h3, h4, h5, h6 {
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

h2, h3 {
    font-weight: bold;
}

h3.section-title {
    border-left: 5px solid $orange;
    color: $orange;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 1;
    padding-left: 20px;
}

.content-container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
}

.content-container-narrow {
    max-width: 1255px;
    width: 100%;
    margin: 0 auto;
}

.btn {
    background: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    line-height: 1;
    margin-left: 0;
    margin-right: 0;
    padding: 0;

    &-link {

        &:link,
        &:visited,
        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    &-default {
        font-size: 1.3rem;
        font-weight: bold;
        min-height: 40px;
        padding: 12px 35px;
        transition: $trans-fast;
    }

    &-brand {
        background-color: $brand;
        color: $default;

        &:hover {
            background-color: $brand;
            color: $default;
        }
    }

    &-more {
        background-color: $orange;

        &:link,
        &:visited,
        &:hover,
        &:active {
            color: $default;
        }

        &:hover {
            background-color: rgba($orange, .75);
        }
    }
}

.newsletter {

    &-home {
        background-color: $gray-newsletter;
        color: $default;
        margin: 30px 0;

        input[type="text"] {
            background-color: rgba($default, .25) !important;
        }

        .col-form {
            overflow: hidden;

            > div > div {
                background: $gray-newsletter !important;
            }
        }
    }

    &-sub {
        color: $default;
        padding: 0 15px;

        .col-text {

            @media (max-width: 1199px) {
                padding-bottom: 0 !important;
            }
        }

        .col-form {
            overflow: hidden;
        }
    }

    .content-container-narrow {
        background-color: $orange;
    }

    .col-text,
    .col-form {
        padding: 50px 36px;

        @include mobile {
            padding: 36px;
        }
    }

    .col-text {

        @include mobile {
            padding-bottom: 0;
        }
    }

    .col-text {

        h3 {
            font-size: 1.7rem;
            font-weight: bold;
            letter-spacing: 0.3px;
            margin-bottom: 20px;
        }

        p {
            color: $default;
            font-size: 1.4rem;
            font-weight: 300;
            letter-spacing: 0.4px;
        }
    }

    .col-form {
        display: flex;
        align-items: center;

        > div {
            flex-shrink: 0;
            width: 100%;

            > div {
                width: 100%;
                padding: 0 !important;
                border-radius: 0 !important;
                background: transparent !important;
            }
        }

        form {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;

            > div, p {
                margin: 0 !important;
            }

            > p {
                @include mobile-xs {
                    width: 100%;
                }
            }

            @include mobile-xs {
                flex-direction: column;
            }

            input[type='text'] {
                background: none;
                background-color: $orange-light;
                background-image: url(../images/newsletter.svg);
                background-position: 30px;
                background-repeat: no-repeat;
                border: none !important;
                color: $default !important;
                flex: 1;
                font-size: 1.4rem !important;
                height: 50px !important;
                padding: 0 !important;
                padding-left: 65px !important;
                width: 100% !important;
                transition: $trans-fast;

                @include mobile-xs {
                    flex: auto;
                    width: 100% !important;
                }

                &::placeholder {
                    color: $default;
                    font-size: 1.4rem;
                }

                &:focus {
                    background-position: 20px;
                    outline: none;
                    padding-left: 50px;
                }
            }

            button[type="submit"] {
                background-color: $default !important;
                border: none !important;
                border-radius: 0 !important;
                color: $orange !important;;
                cursor: pointer !important;;
                font-size: 1.8rem !important;;
                font-weight: bold !important;;
                flex-basis: 160px !important;;
                height: 50px !important;;
                width: 160px !important;;

                @include mobile-xs {
                    flex-basis: auto !important;
                    width: 100% !important;
                }

                &:focus {
                    outline: 2px dashed $brand;
                }
            }

            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            span.message_error {
                color: red;
                width: 100%;
            }

            > div {
                float: none !important;
                width: auto !important;
                flex: 1;

                > div {
                    width: 100%;

                    input {
                        padding: 0 20px !important;
                        height: 40px !important;
                        color: #fff !important;
                        font-size: 1.4rem !important;
                        border: none !important;
                        background: rgba(#fff, .25);
                        width: 100%;

                        &::placeholder {
                            color: #fff;
                        }
                    }
                }
            }

            > p:nth-of-type(1) {
                order: 3;
                color: white;
                display: flex;
                justify-content: flex-start;
                width: 100%;
                margin-top: 15px !important;

                input {
                    margin-right: 5px;
                    margin-top: 2px;
                }

                a {
                    color: white;
                    text-decoration: underline;
                }

                span {
                    color: white;
                    cursor: pointer;
                    font-size: 1.2rem;
                    line-height: 1.4;
                    max-height: calc(1.2rem * 4);
                    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
                    overflow: hidden;
                    transition-delay: 1s;

                    a {
                        color: white;
                        text-decoration: underline;
                    }

                    &:hover {
                        max-height: 500px;
                        transition: max-height 1s ease-in-out;
                        transition-delay: 0s;
                    }
                }
            }

            > p:nth-of-type(2) {
                order: 2;
                max-width: 130px;
                margin: 0 !important;
                text-align: left !important;
                display: flex;
                align-items: center;
                line-height: normal;
            }

        }
    }
}

//Post excerpt: zwykły
.post-excerpt {
    border-bottom: 1px solid $gray-border;
    display: flex;
    flex-direction: column;
    z-index: 1;

    @include mobile {
        border-left: 1px solid $gray-border;
    }

    @include tablet-and-greater {
        &:nth-child(2n+1) .post-excerpt-text {
            border-left: 1px solid $gray-border;
        }
    }

    &:hover {
        .post-excerpt-thumb {
            opacity: .5;
        }
    }

    &-thumb {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 150px;
        transition: $trans-fast;
    }

    &-text {
        border-right: 1px solid $gray-border;
        flex: 1 0 auto;
        padding: 20px 25px 25px;
    }

    &-title {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.25;
        margin-bottom: 20px;
    }

    &-meta {
        display: flex;
        margin-bottom: 15px;
    }

    &-date {
        margin-right: 15px;
        font-size: 1.4rem;
    }

    &-author {
        color: $gray-utils;
        display: flex;
        flex-direction: column;
        font-size: 1.4rem;
        font-weight: bold;

        &-caption {
            color: $text-color;
            font-size: 1.3rem;
            font-weight: 300;
        }
    }

    &-topics {
        font-size: 1.3rem;
        font-weight: 300;

        &-label {
            font-weight: 500;
            margin-right: 5px;
        }

        a {
            transition: $trans-fast;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    //Post excerpt: ciemny
    &.post-excerpt-dark {
        background-color: $brand;
        color: $default;

        .post-excerpt-title {
            color: $default;
        }

        .post-excerpt-author {
            color: $default;

            &-caption {
                color: $default;
                opacity: .5;
            }
        }

        .post-excerpt-topics {

            a {
                color: $default;

                &:hover {
                    color: $orange;
                    text-decoration: none;
                }
            }
        }
    }

    //Post excerpt: promowany
    &.post-excerpt-featured {
        border: 0;
        width: 100%;
        max-width: 400px;
        padding: 40px 35px 30px;

        @include mobile-xs {
            margin: 0 15px;
            padding: 20px 18px 15px;
        }

        .post-excerpt-date {
            display: block;
            font-size: 1.4rem;
            margin-bottom: 15px;
            margin-right: 0;
        }

        .post-excerpt-title {
            display: block;
            font-size: 3.2rem;
            font-weight: bold;
            line-height: 1.25;
            margin-bottom: 10px;
        }

        .post-excerpt-author {
            font-size: 1.3rem;
        }
    }

    //Post excerpt: sidebar
    &.post-excerpt-sidebar {
        border: 0;

        .post-excerpt-title {
            color: $default;
            font-size: 1.7rem;
            font-weight: 300;
            line-height: 1.5;
            margin-bottom: 10px;
        }

        .post-excerpt-meta {
            margin-bottom: 0;
        }

        .post-excerpt-date {
            font-size: 1.2rem;
            font-weight: 300;
        }

        .post-excerpt-author {
            font-size: 1.3rem;
        }
    }

    //Post excerpt: others
    &.post-excerpt-others {

        .post-excerpt-meta {
            margin-bottom: 0;
        }

        .post-excerpt-author {
            color: $gray-utils;
        }
    }

    //Post excerpt: w .top na podstronie
    &.post-excerpt-top {
        border: 0;
        color: $default;

        .post-excerpt-text {
            border: 0;
            flex: auto;
            padding: 0;
        }

        .post-excerpt-title {
            font-size: 6rem;
            font-weight: bold;
            word-break: break-word;
        }

        .post-excerpt-author {
            color: $default;
            font-size: 1.6rem;
        }
    }
}

.about-author {
    background-color: $brand;
    color: $default;

    &-heading {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }

    &-avatar {
        margin-right: 20px;
    }

    &-info {
        display: flex;
        flex-direction: column;
        font-weight: bold;
    }

    &-caption {
        font-weight: 300;
        opacity: .5;
    }

    &-desc {

        p {
            font-size: 1.5rem;
            font-weight: 300;
            letter-spacing: .3px;
            line-height: 1.7;

            a {
                color: white;
                text-decoration: underline;
            }

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        strong {
            font-size: 1.6rem;
            font-weight: bold;
        }
    }
}

.person {
    background-color: $brand;
    color: $default;
    display: flex;
    align-items: flex-start;
    padding: 30px 0 0;
    position: relative;

    @include mobile-xs {
        flex-direction: column;
    }

    &-avatar {
        margin-right: 25px;

        @include mobile-xs {
            margin-bottom: 30px;
            margin-right: 0;
        }
    }

    &-ask {
        display: flex;
        align-items: center;
        position: absolute;
        right: 35px;

        @include mobile-xs {
            right: 18px;
            top: 75px;
        }

        img {
            margin-right: 10px;
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        font-size: 1.4rem;
        width: 100%;

        i {
            font-size: 1rem;
            margin-right: 15px;
        }

        a {
            color: $default;
            transition: $trans-fast;

            &:hover {
                color: $orange;
            }
        }
    }

    &-name {
        font-weight: 500;
    }

    &-caption {
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 20px;
        opacity: .5;
    }

    &-content {
        display: flex;

        @include mobile-xs {
            flex-direction: column;
        }

        > * {
            min-width: 50%;
        }
    }

    &-items {
        display: flex;
        flex-direction: column;

        span, a {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }

        a {
            word-break: break-all;
        }
    }
}

.others-heading {
    padding: 25px 35px;

    &-dark {
        background-color: $brand;
    }

    &-border {
        border-bottom: 1px solid rgba($gray-border, .1);
    }

    &-bordered {
        border-top: 1px solid $gray-border;
        border-left: 1px solid $gray-border;
        border-right: 1px solid $gray-border;
    }
}

.top {
    background-color: $gray-img1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 720px;
    padding: 0 15px;
}

.tags {
    display: flex;
    flex-wrap: wrap;

    .tags-label {
        display: block;
    }

    .tag {
        border: 1px solid rgba($default, .5);
        color: $default;
        display: inline-block;
        font-size: 1.3rem;
        margin-bottom: 10px;
        margin-right: 10px;
        padding: 3px 15px;
        transition: $trans-fast;

        &:hover {
            color: $orange;
        }
    }
}

.pager {
    margin: 20px 0;

    a, span {
        margin: 0 5px;
    }

    a {
        color: $orange;
        font-weight: bold;
    }
}
