.publications {
  padding: 50px 15px 0;

  .section-title {
    margin-bottom: 45px;
  }

  .publications-list {
    border-top: 1px solid $gray-border;
    margin-bottom: 40px;

    .col-item {
      border-bottom: 1px solid $gray-border;

      @media (max-width: 991px) {

        &:not(:last-child) {
          border-bottom: 1px solid $gray-border;
        }

        .text {
          border-left: 1px solid $gray-border;
        }
      }

      @media (min-width: 992px) {

        &:nth-child(3n+1) {
          .text {
            border-left: 1px solid $gray-border;
          }
        }
      }

      .text {
        border-right: 1px solid $gray-border;
        height: 100%;
        padding: 45px 30px 55px;

        @include mobile {
          border-left: 1px solid $gray-border;
        }

        .title {
          color: $black;
          font-size: 2.2rem;
          font-weight: bold;
          line-height: 1.25;
          margin-bottom: 20px;

          &.ext-url {

            &::after {
              display: inline-block;
              font-family: 'Font Awesome\ 5 Free';
              color: $gray;
              content: ' \f35d';
              font-size: 1rem;
              font-weight: 900;
              margin-left: 5px;
              position: relative;
              top: -3px;
            }
          }
        }

        .meta {
          display: flex;
          margin-bottom: 20px;

          .date {
            color: $black;
            display: inline-block;
            margin-right: 15px;
          }

          .author {
            color: $gray;
            display: flex;
            flex-direction: column;
            font-size: 1.4rem;
            font-weight: bold;
            letter-spacing: 0.3px;
            text-transform: uppercase;

            .caption {
              font-size: 1.3rem;
              font-weight: 300;
              text-transform: none;
            }
          }
        }

        p {
          color: $black;
          line-height: 1.65;
        }
      }
    }

    .no-results {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 400px;

      h2 {
        color: $brand;
        font-size: 2.2rem;
        font-weight: bold;
      }
    }
  }

  .filters {
    display: flex;
    margin-bottom: 50px;

    @include mobile {
      flex-direction: column;
    }

    .item {
      border-bottom: 2px solid $brand;

      @include mobile {
        padding: 10px 0;
      }

      &:not(:last-child) {

        @include tablet-and-greater {
          margin-right: 55px;
        }
      }
    }

    input {
      background: url(../images/search-dark.svg) no-repeat top right;
      border: 0;
      width: 225px;
      padding-right: 30px;

      @include mobile {
        width: 100%;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $gray-border;
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
    }

    .dropdown {

      .btn {
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;
        min-width: 140px;
        padding-right: 20px;
        position: relative;
        text-align: left;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      .dropdown-toggle {

        @include mobile {
          width: 100%;
        }

        &::after {
          font-size: 2rem;
          position: absolute;
          right: 0;
          top: 4px;
        }
      }

      .dropdown-menu {
        border: 0;
        box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
        margin: 0;
        margin-top: 8px;
        padding: 10px 0;

        .dropdown-item.active,
        .dropdown-item:active {
          background-color: $brand;
        }

        a {
          cursor: pointer;
          font-size: 1.5rem;
          font-weight: bold;
          letter-spacing: 3px;
          text-transform: uppercase;
          padding: 5px 10px;
        }
      }
    }
  }

  .newsletter {
    margin-bottom: -105px;
    position: relative;
    z-index: 10;
  }
}
